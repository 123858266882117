<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> जग्गाधनीको विवरण </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row class="mt-2">
          <v-col cols="6" md="3">
            <label> जग्गा धनीको किसिम<span> *</span></label>
          </v-col>
          <v-col cols="6" md="4">
            <!-- <v-text-field v-model="newlandowner.landowner_type_id"></v-text-field> -->
            <v-select v-model="newlandowner.landowner_type_id" :rules="[required('Landowner Type')]" :items="landownerType" item-value="id" item-text="landowner_type_name"
              :key="id"></v-select>
          </v-col>
          <!-- <v-col cols="4" v-if="regConstructionId !== 1">
            <v-switch
              v-model="same_as_old_data"
              label="Same as Old Data"
              color="primary"
              hide-details
            ></v-switch>
          </v-col> -->
        </v-row>
        <div v-if="newlandowner.landowner_type_id == 5">
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको नाम <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-select v-model="houseOwnerId" :items="houseownerinfo" item-value="id" item-text="name_np" @change="houseownerDetail()" :key="id"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> दर्ता भएको जिल्ला <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-select v-model="newlandowner.citizenship_district_id" label="दर्ता भएको जिल्ला" :rules="required('Citizenship District')" :items="districts" item-value="id"
                item-text="district_name" :key="id" clearable dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको VAT No./PAN No. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.citizenship_no" :rules="required('VAT No./PAN No.')" label="VAT No./PAN No."></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको दर्ता मिति</label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.citizenship_issue_date_np" label="संस्थाको दर्ता मिति" dense class="custom-text-field" ref="dateField"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> ठेगाना <span> *</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newlandowner.address_street" label="ठेगाना" dense required></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="6" md="3"> घरधनी <span> *</span></v-col>
            <v-col cols="6" md="4">
              <v-select v-model="houseOwnerId" :items="houseownerinfo" item-value="id" item-text="name_np" @change="houseownerDetail()" :key="id"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> श्रीमान / श्रीमती<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-select v-model="newlandowner.salutation" :rules="[required('Salutation')]" :items="options" item-value="value" class="custom-text-field" item-text="value"
                :key="id"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3"> House Owner Name </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.landowner_name" label="" :rules="requiredFormat('Houseowner Name', `${this.$regexList.engName}`)"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> जग्गा धनीको नाम<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.landowner_name_np" :rules="requiredFormat('Landowner Name', `${this.$regexList.nepName}`)" label=""></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> बुबा/पतीको नाम<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.father_name_np" :rules="requiredFormat('Father\'s Name', `${this.$regexList.nepName}`)" label=""></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-col md="2">
            <label> Father's/Husband's Name<span> *</span></label>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="newlandowner.father_name"
              :rules="requiredFormat('Father or Husband\'s Name', `${this.$regexList.engName}`)"
              label=""
            ></v-text-field>
          </v-col>
        </v-row> -->
          <v-row>
            <v-col cols="6" md="3"> हजुरबुबा/ससुराको नाम </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.grandfather_name" label=""></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-col md="2">
            Grandfather's Name
          </v-col>
          <v-col md="4">
            <v-text-field v-model="newlandowner.grandfather_name_np" label=""></v-text-field>
          </v-col>
        </v-row> -->
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता लिएको जिल्ला<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-autocomplete v-model="newlandowner.citizenship_district_id" :rules="[required('Citizenship District')]" :items="districts" item-value="id" item-text="district_name"
                :key="id"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> फोन नं.<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.phone_no" :rules="requiredPhone('Phone No.')" label=""></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता न.<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.citizenship_no" :rules="[required('Citizenship No.')]" label=""></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता लिएको मिति<span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.citizenship_issue_date_np" :rules="requiredFormat('Citizenship Issue Date', `${this.$regexList.nepDate}`)" label="" required
                ref="dateField"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3"> ठेगाना </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.address_street" :rules="nepAddress('ठेगाना')" label="" required></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6" md="3"> Address </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.address_street_np" label="" required></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" md="3"> टोल </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.tole" label="" :rules="nepAddress('टोल')" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3"> घर नं </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newlandowner.house_no" :rules="nepAddress('घर नं')" class="custom-text-field date-picker" required></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col class="my-4">
            <v-btn primary v-if="isPostAuthorized" v-on:click="save(false)" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ml-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
            <v-snackbar v-model="snackbar.show" :bottom="y === 'bottom'" :color="snackbar.color" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'"
              :timeout="3000" :top="y === 'top'" :vertical="mode === 'vertical'">
              {{ successfulMessage }}
            </v-snackbar>
          </v-col>
        </v-row>
        <br>
        <br>
        <br>
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">जग्गा धनी</th>
              <th class="text-left registrationtable table-title">नागरिकता न.</th>
              <th class="text-left registrationtable table-title">नागरिकता लिएको मिति</th>
              <th class="text-left registrationtable table-title">फोन</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="landownerList in landowner" :key="landownerList.id">
              <td class="registrationtable">
                {{ landownerList.landowner_name_np }}
              </td>
              <td class="registrationtable">
                {{ landownerList.citizenship_no }}
              </td>
              <td>{{ landownerList.citizenship_issue_date_np }}</td>
              <td>{{ landownerList.phone }}</td>
              <td>
                <v-btn small color="primary" v-if="isPostAuthorized" v-on:click="edit(landownerList.id)">Edit</v-btn>
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(landownerList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog v-if="isPostAuthorized" :click-dialog="deleterecord" :regId="landownerList.id" click-type="delete">Delete</custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(landownerList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>

<script>
import * as nepaliDatePicker from "../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
import "../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";
import FormHistory from "../../history/FormHistory.vue";

export default {
  name: "Landowner",
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },
  components: {
    FormHistory,
  },

  data() {
    return {
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/landowner-info/history`,
        formData: [
          {
            title: "निवेदकको नाम ",
            key: "landowner_name",
          },
          {
            title: "निवेदकको नाम (EN)",
            key: "landowner_name_np",
          },
          {
            title: "हजुरबुबा/ससुराको नाम",
            key: "grandfather_name_np",
          },
          {
            title: "नागरिकता न.",
            key: "citizenship_no",
          },
          {
            title: "ठेगाना",
            key: "address_street",
          },
          {
            title: "टोल",
            key: "tole",
          },
          {
            title: "फोन नं",
            key: "phone_no",
          },
          {
            title: "ईमेल",
            key: "email",
          },
        ],
      },
      required(propertyType) {
        return (v) => !!v || `${propertyType} is required`;
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} is reqiuired`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} is required`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
      nepAddress(propertyType) {
        return [
          (v) => /^[\u0900-\u096F -\/]*$/.test(v) || !v || `${propertyType}को फर्मेट मिलेन ।`,
        ];
      },
      date: "",
      value: 2,
      val1: "",
      show: false,
      houseOwnerId: 0,
      houseownerinfo: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      landowner: [],
      districts: [],
      options: [
        { id: "1", value: "श्रीमान" },
        { id: "2", value: "श्रीमती" },
      ],
      isValid: true,
      isLoading: true,
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      newlandowner: {
        id: 0,
        reg_id: this.registrationId,
        landowner_type_id: "",
        landowner_name: "",
        landowner_name_np: "",
        ward_no: "",
        salutation: "",
        father_name: "",
        father_name_np: "",
        grandfather_name: "",
        grandfather_name_np: "",
        citizenship_no: "",
        citizenship_district_id: "",
        citizenship_issue_date: "2020-5-5",
        citizenship_issue_date_np: "",
        address_street: "",
        address_street_np: "",
        tole: "",
        house_no: "",
        phone_no: "",
      },
      landownerType: [],
      isPostAuthorized: false,
    };
  },
  mounted() {
    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        this.newlandowner.citizenship_issue_date_np = event.datePickerData.formattedDate;
      });
    }
    const url = `api/registration/${this.registrationId}/landowner-info`;
    this.$axios
      .get(url)
      .then((response) => {
        //   debugger;
        this.landowner = response.data.data;
        this.edit(this.landowner[0].id);
        this.isLoading = false; //stop the loading spinner
        //   console.log(this.landowner);
        // this.newlandowner.clear();
      })
      .catch((error) => {
        console.log(`api error:${error}`);
        this.isLoading = false; //stop the loading spinner
      });
    const districtUrl = "api/districts";
    this.$axios
      .get(districtUrl)
      .then((response) => {
        // debugger;
        this.districts = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const houseownerUrl = `api/registration/${this.registrationId}/houseowner-detail`;
    this.$axios
      .get(houseownerUrl)
      .then((response) => {
        // debugger;
        this.houseownerinfo = response.data.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
    const landownertypeUrl = "api/landowner-type";
    this.$axios
      .get(landownertypeUrl)
      .then((response) => {
        // debugger;
        this.landownerType = response.data;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  created() {
    // this.getRole();
    this.getNibedanAuthorization();
  },
  methods: {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    save(isNext) {
      // debugger;
      this.isLoading = true;
      if (
        this.newlandowner.id === null ||
        this.newlandowner.id === 0 ||
        this.newlandowner.id === undefined
      ) {
        const saveUrl = `api/registration/${this.registrationId}/landowner-info`;
        this.$axios
          .post(saveUrl, this.newlandowner)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.landowner.push(response.data.data);
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              // this.alertSuccess("Successfully Added !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/landowner-info/${this.newlandowner.id}`;
        this.$axios
          .put(eurl, this.newlandowner)
          .then((response) => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              // this.alertUpdate("Successfully Updated !!");
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
              this.newlandowner.id = 0;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            let message = [];
            let errorResponse = error.response.data.errors;
            for (var i in errorResponse) {
              // errorText = errorText + `${i}: ${errorResponse[i]} \n`;
              message.push(errorResponse[i][0] + "<br>");
            }
            this.alertError(message);
          });
      }
    },
    houseownerDetail() {
      const url = `api/registration/${this.registrationId}/houseowner-detail/${this.houseOwnerId}`;
      this.$axios.get(url).then((response) => {
        this.newlandowner.salutation = response.data.data.salutation;
        this.newlandowner.landowner_name = response.data.data.name;
        this.newlandowner.landowner_name_np = response.data.data.name_np;
        this.newlandowner.father_name = response.data.data.father_or_husband_name;
        this.newlandowner.father_name_np = response.data.data.father_or_husband_name_np;
        this.newlandowner.grandfather_name = response.data.data.grandfather_name;
        this.newlandowner.grandfather_name_np = response.data.data.grandfather_name_np;
        this.newlandowner.phone_no = response.data.data.phone_no;
        this.newlandowner.citizenship_issue_date_np = response.data.data.citizenship_issue_date_np;
        this.newlandowner.citizenship_district_id = response.data.data.citizenship_district_id;
        this.newlandowner.citizenship_no = response.data.data.citizenship_no;
        this.newlandowner.tole = response.data.data.tole;
        this.newlandowner.address_street = response.data.data.address;
        //   this.newlandowner.ward_no = response.data.data.ward_no;
      });
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/landowner/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/landowner-info/${id}`;

      this.$axios.get(url).then((response) => {
        // debugger;
        this.newlandowner = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/landowner-info/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            // this.alertDelete("Successfully Deleted !!");
            const i = this.landowner.map((item) => item.id).indexOf(id); // find index of your object
            this.designer.splice(i, 1);
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 60px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}

label span {
  color: red;
}

.table-title {
  font-size: 17px;
}
</style>
