<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> घरधनीको विवरण </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="mx-4" v-model="valid">
        <v-row class="mt-2">
          <v-col cols="6" md="3">
            <label for="houseowner-type">घरधनीको प्रकार</label>
          </v-col>
          <v-col cols="6" md="4"><v-select v-model="newhouseownerinfo.houseowner_type" label="घरधनीको प्रकार" :items="houseowner_type_items" item-text="name"
              item-value="id"></v-select></v-col>
          <!-- <v-col cols="4" v-if="regConstructionId !== 1">
            <v-switch
              v-model="same_as_old_data"
              label="Same as Old Data"
              color="primary"
              hide-details
            ></v-switch>
          </v-col> -->
        </v-row>
        <div v-if="newhouseownerinfo.houseowner_type == 'p'">
          <v-row>
            <!-- <v-col md="3">
            <label> शीर्षक <span> *</span></label>
          </v-col>
          <v-col cols="4">
            <v-select
              label="शीर्षक"
              :rules="[required('Salutation')]"
              v-model="newhouseownerinfo.salutation"
              :items="options"
              item-value="value"
              item-text="value"
              :key="id"
              dense
            ></v-select>
          </v-col> -->
            <v-col cols="6" md="3">
              <label> फोटो <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <input type="file" id="file-upload" @change="onFileChange" /> -->
              <v-file-input v-model="newhouseownerinfo.photo" label="Select File..." outlined></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> नाम <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.name_np" :rules="requiredFormat('Houseowner Name (NP)', `${this.$regexList.nepName}`)" label="नाम" class="custom-text-field"
                dense></v-text-field>
            </v-col>
            <v-col cols="6" md="4" style="text-align: center">
              <img :src="uploadedDocument" width="200px" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> Full Name <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.name" :rules="requiredFormat('Houseowner Name', `${this.$regexList.engName}`)" label="Full Name" class="custom-text-field"
                dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label>बुबा/पतीको नाम <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.father_or_husband_name_np" :rules="requiredFormat('Father/Husband Name(NP)', `${this.$regexList.nepName}`)"
                class="custom-text-field" label="बुबा/पतीको नाम" dense></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-col md="3">
            <label> Father's/Husband's Name <span> *</span></label>
          </v-col>
          <v-col md="4">
            <v-text-field
              v-model="newhouseownerinfo.father_or_husband_name"
              :rules="requiredFormat('Father/Husband Name', `${this.$regexList.engName}`)"
              label="Father/Husband's Name "
              dense
            ></v-text-field>
          </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" md="3">
              <label> हजुरबुबा/ससुराको नाम <span> *</span> </label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.grandfather_name_np" :rules="requiredFormat(
                  'Grandfather/Father-in-law Name (NP)',
                  `${this.$regexList.nepName}`
                )
                " label="हजुरबुबा/ससुराको नाम" dense></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
          <v-col cols="6" md="3">
            <label> Grandfather's Name <span> *</span> </label>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="newhouseownerinfo.grandfather_name"
              :rules="requiredFormat('Grandfather Name', `${this.$regexList.engName}`)"
              label="Grandfather's Name"
              dense
            ></v-text-field>
          </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता लिएको जिल्ला <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-autocomplete v-model="newhouseownerinfo.citizenship_district_id" label="नागरिकता लिएको जिल्ला" :rules="required('Citizenship District')" :items="districts"
                item-value="id" item-text="district_name" :key="id" clearable dense></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label>फोन नं. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.phone_no" :rules="requiredPhone('Phone No.')" type="text" label="Phone" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> Email</label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.email" :rules="emailRules()" type="text" label="Email" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता न. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.citizenship_no" :rules="required('Citizenship No.')" label="नागरिकता न." dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> Ward Number/ वडा नं. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <v-text-field v-model="newhouseownerinfo.ward_no" :rules="requiredFormat('Ward No. ', `${this.$regexList.nepEngNo}`)" label="Ward Number" dense type="number"
                required></v-text-field> -->
              <v-select :items="wards" v-model="newhouseownerinfo.ward_no" :rules="requiredFormat('Ward No. ', `${this.$regexList.nepEngNo}`)" label="" required dense></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> नागरिकता जारी भएको मिति <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <NepaliDatePicker v-model="newhouseownerinfo.citizenship_issue_date_np" label="नागरिकता लिएको मिति" :rules="requiredFormat('Citizenship Date', `${this.$regexList.nepDate}`)"/> -->
              <v-text-field v-model="newhouseownerinfo.citizenship_issue_date_np" :rules="requiredFormat('Citizenship Date', `${this.$regexList.nepDate}`)"
                label="नागरिकता लिएको मिति" dense class="custom-text-field" ref="dateField" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> ठेगाना <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.address" :rules="requiredNepAddress('ठेगाना')" label="ठेगाना" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> टोल <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.tole" :rules="requiredNepAddress('टोल')" label="टोल" dense tole required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> घर नं </label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.house_no" label="घर नं" dense class="custom-text-field date-picker"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-if="newhouseownerinfo.houseowner_type == 'c'">
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको नाम <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.name_np" :rules="required('Company Name')" label="संस्थाको नाम"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> फोटो/ संस्थाको लोगो </label>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <input type="file" id="file-upload" @change="onFileChange" /> -->
              <v-file-input v-model="newhouseownerinfo.photo" label="Select File..."></v-file-input>
            </v-col>
            <v-col cols="6" md="4" style="text-align: center">
              <img :src="uploadedDocument" width="200px" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> दर्ता भएको जिल्ला <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <!-- <v-text-field v-model="newhouseownerinfo.citizenship_district_id" label=""     ></v-text-field>
                                 -->
              <v-select v-model="newhouseownerinfo.citizenship_district_id" label="दर्ता भएको जिल्ला" :rules="required('Citizenship District')" :items="districts" item-value="id"
                item-text="district_name" :key="id" clearable dense></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="3">
              <label for="company_reg_no">संस्थाको दर्ता नं. <span> *</span></label>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="newhouseownerinfo.citizenship_no"
                :rules="required('Company Registration Number')"
                label="संस्थाको दर्ता नं."
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको VAT No./PAN No. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.citizenship_no" :rules="required('VAT No./PAN No.')" label="VAT No./PAN No."></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label for="company_reg_no">संस्थाको दर्ता मिति</label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.citizenship_issue_date_np" label="संस्थाको दर्ता मिति" dense class="custom-text-field" ref="dateField"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> फोन नं. <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.phone_no" :rules="requiredPhone('Phone No.')" type="text" label="Phone" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> Email</label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.email" :rules="emailRules()" type="text" label="Email" dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3">
              <label> ठेगाना <span> *</span></label>
            </v-col>
            <v-col cols="6" md="4">
              <v-text-field v-model="newhouseownerinfo.address" label="ठेगाना" :rules="requiredNepAddress('ठेगाना')" dense required></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
          {{ successfulMessage }}
        </v-snackbar>
        <v-row>
          <v-col class="my-4">
            <v-btn primary v-if="isPostAuthorized" v-on:click="save(false)" class="primary" :disabled="!valid">Save</v-btn>
            <v-btn primary v-on:click="save(true)" v-if="isPostAuthorized" class="primary ma-1" :disabled="!valid">Save &amp; Next</v-btn>
            <v-btn class="ma-1" color="primary" @click="nextStep(1)"> Skip </v-btn>
          </v-col>
        </v-row>
        <br /><br /><br />
      </v-form>
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th class="text-left registrationtable table-title">घरधनीको नाम</th>
              <th class="text-left registrationtable table-title">फोन</th>
              <th class="text-left registrationtable table-title">नागरिकता न</th>
              <th class="text-left registrationtable table-title">नागरिकता लिएको मिति</th>
              <th class="text-left registrationtable table-title">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="houseownerinfoList in houseownerinfo" :key="houseownerinfoList.id">
              <td class="registrationtable">
                {{ houseownerinfoList.name_np }}
              </td>
              <td>{{ houseownerinfoList.phone_no }}</td>
              <td>{{ houseownerinfoList.citizenship_no }}</td>
              <td class="registrationtable">
                {{ houseownerinfoList.citizenship_issue_date_np }}
              </td>
              <td>
                <v-btn small color="primary" v-if="isPostAuthorized" v-on:click="edit(houseownerinfoList.id)">Edit</v-btn>
                <!-- <v-btn
                  small
                  color="primary"
                  v-if="isPostAuthorized"
                  v-on:click="deleterecord(houseownerinfoList.id)"
                  >Delete</v-btn
                > -->
                <custom-dialog v-if="isPostAuthorized" :click-dialog="deleterecord" :regId="houseownerinfoList.id" click-type="delete">
                  Delete
                </custom-dialog>
                <v-btn small color="primary" v-else v-on:click="edit(houseownerinfoList.id)">View</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <form-history :historyData="historyData"></form-history>
  </div>
</template>
<script>
import * as nepaliDatePicker from '../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min'; /*eslint-disable-line*/
// import '../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css';
import FormHistory from "../../history/FormHistory.vue";
import NepaliDatePicker from "@/custom-inputs/nepali-date-picker/NepaliDatePicker.vue";
import { engNumConvert } from "@/modules/mixins/numericToUnicode.js";

export default {
  name: "Houseownerinfo",
  mixins: [engNumConvert],
  props: {
    nextStep: {
      type: Function,
    },
    registrationId: {
      type: [String, Number],
    },
    id: {
      type: [String, Number],
    },
    application: {
      type: Object,
    },
    // isPostAuthorized: {
    //   type: Boolean,
    // },
    regConstructionId: {
      type: Number,
    },
  },
  components: {
    FormHistory,
    // NepaliDatePicker
  },

  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      valid: false,
      same_as_old_data: false,
      historyData: {
        apiUrl: `api/registration/${this.registrationId}/houseowner-detail/history`,
        formData: [
          {
            title: "नाम",
            key: "name",
          },
          {
            title: "निवेदकको नाम (EN)",
            key: "name_np",
          },
          {
            title: "नागरिकता नं",
            key: "citizenship_no",
          },
          {
            title: "बुबाको नाम",
            key: "father_or_husband_name_np",
          },
          {
            title: "हजुरबुबाको नाम",
            key: "grandfather_name_np",
          },
          {
            title: "फोन नं",
            key: "phone_no",
          },
          {
            title: "ठेगाना",
            key: "address",
          },
          {
            title: "टोल",
            key: "tole",
          },
          {
            title: "वार्ड नं",
            key: "ward_no",
          },
        ],
      },
      required(propertyType) {
        return [(v) => !!v || `${propertyType} भर्न आवश्यक छ ।`];
      },
      requiredNumber(propertyType) {
        return [(v) => (v && v > 0) || `${propertyType} भर्न आवश्यक छ ।`];
      },
      requiredFormat(propertyType, regexexp) {
        var regex = new RegExp(regexexp, "g");
        return [
          (v) => !!v || `${propertyType} भर्न आवश्यक छ ।`,
          (v) => regex.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
          // /^२[०-९]{3}-[०१]?[०-९]-[०-३]?[०-९]$/   ‍=> Date
        ];
      },
      requiredNepaliName(propertyType) {
        return [
          (v) => !!v || `${propertyType} भर्न आवश्यक छ ।`,
          (v) =>
            /^[\u0900-\u096F ]*[^\u0966-\u096F]*$/.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
        ];
      },
      requiredEngString(propertyType) {
        return [
          (v) => !!v || `${propertyType} भर्न आवश्यक छ ।`,
          (v) => /^[a-zA-Z ]+$/.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
        ];
      },
      requiredPhone(propertyType) {
        return [
          (v) => !!v || `${propertyType} भर्न आवश्यक छ ।`,
          // v => /^\w$/ || 'Please write in english format',
          (v) =>
            /^\+?(977|९७७)? ?[0-9०-९]{9,15}$/.test(v) ||
            `${propertyType} is not valid phone number`,
        ];
      },
      emailRules() {
        return [
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || !v || `ईमेलको फर्मेट मिलेन ।`,
        ];
      },
      requiredNepAddress(propertyType) {
        return [
          (v) => !!v || `${propertyType} भर्न आवश्यक छ ।`,
          (v) => /^[\u0900-\u096F -\/]*$/.test(v) || `${propertyType}को फर्मेट मिलेन ।`,
        ];
      },
      date: "",
      optionsOld: [
        { id: 1, value: "श्रीमान" },
        { id: 2, value: "श्रीमती" },
      ],
      options: [{ id: 1, value: "श्री" }],
      error: "",
      isLoading: true,
      isValid: true,
      districts: [],
      photo_name: "",
      // formData: new FormData(),
      userRoleId: localStorage.getItem("auth_user_role_id"),
      houseownerinfo: [],
      name: false,
      nameNp: false,
      newhouseownerinfo: {
        id: 0,
        houseowner_type: "p",
        // houseowner_type: { name: "व्यक्ति", id: "p" },
        reg_id: this.registrationId,
        salutation: "श्री",
        citizenship_no: "",
        name: "",
        name_np: "",
        father_or_husband_name: "none",
        father_or_husband_name_np: "",
        grandfather_name: "",
        grandfather_name_np: "",
        citizenship_district_id: 100,
        phone_no: "",
        email: "",
        citizenship_issue_date: "2016-8-8",
        citizenship_issue_date_np: "",
        address: "",
        tole: "",
        ward_no: 0,
        house_no: "",
        photo: "",
      },
      newRegData: {},
      uploadedDocument: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      houseowner_type_items: [
        { name: "व्यक्ति", id: "p" },
        { name: "संस्था", id: "c" },
      ],
      isPostAuthorized: false,
      wards: [],
    };
  },
  created() {
    // alertSuccess(message) {
    //   this.$swal("Added!", message, "success");
    // },
    // alertUpdate(message) {
    //   this.$swal("Updated!", message, "success");
    // },
    // alertDelete(message) {
    //   this.$swal("Deleted!", message, "success");
    // },
    this.getNewRegData();
    this.getHouseownerDetail();
    this.getNibedanAuthorization();
  },
  mounted() {
    // for (const [key, value] of Object.entries(this.newhouseownerinfo)) {
    //   console.log(`${key}: ${value}`);
    // }
    this.getMunicipality();


    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        this.newhouseownerinfo.citizenship_issue_date_np = event.datePickerData.formattedDate;
      });
    }
  },
  methods: {
    alertError(message) {
      this.$swal.fire({
        icon: "error",
        title: message,
        text: "Please have a look on the form!",
      });
    },
    getNibedanAuthorization() {
      this.isPostAuthorized = this.$store.getters.nibedanBibaranAuthData;
    },
    async getMunicipality() {
      let munTemp = this.$store.getters.getMunicipality;
      try {

        if (!Object.keys(munTemp).length) {
          await this.$store.dispatch("fetchMunicipality")
        }
        let noOfWards = this.$store.getters.getMunicipality.no_of_wards;
        for (let i = 0; i < noOfWards; i++) {
          // this.wards.push(this.engNumToNep(i + 1));
          this.wards.push(i + 1);
        }
      } catch (error) {
        console.log("Error while retrieving municipality");
        console.log(error);
      }
    },
    getHouseownerDetail() {
      const url = `api/registration/${this.registrationId}/houseowner-detail`;
      this.$axios
        .get(url)
        .then((response) => {
          // debugger;
          this.houseownerinfo = response.data.data;
          this.edit(this.houseownerinfo[0].id);
          this.uploadedDocument = this.basePath + this.houseownerinfo[0].photo;
          // console.log(this.basePath);
          // console.log(this.houseownerinfo[0].photo);
          this.isLoading = false; //stop the loading spinner
          //   this.newhouseownerinfo.clear();
        })
        .catch((error) => {
          console.log(`api error:${error}`);
          this.isLoading = false;
        });
      const districtUrl = "api/districts";
      this.$axios
        .get(districtUrl)
        .then((response) => {
          // debugger;
          this.districts = response.data.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    onFileChange(e) {
      const self = this;
      //   self.formData.append('photo', this.newhouseownerinfo.photo);
      const files = e.target.files || e.dataTransfer.files;
      // console.log(files[0].size);
      if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
        for (let i = 0; i < files.length; i += 1) {
          self.formData.append("photo", files[i], files[i].name);
        }
      } else {
        document.getElementById("file-upload").value = "";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        this.successfulMessage = "Please select file less than 5 Mb!!";
      }
    },
    save(isNext) {
      this.isLoading = true;
      const formData = new FormData();

      for (const [key, value] of Object.entries(this.newhouseownerinfo)) {
        if (value == null || value == "null") {
          formData.append(key, "");
        }
        else {
          formData.append(key, value);
        }
      }

      if (this.newhouseownerinfo.id === null ||
        this.newhouseownerinfo.id === 0 ||
        this.newhouseownerinfo.id === undefined) {
        formData.append("_method", "POST");
        const url = `/api/registration/${this.$route.params.regId}/houseowner-detail`;
        this.$axios
          .post(url, formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.newhouseownerinfo = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Added";
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(`api error:${error}`);
          });
      } else {
        console.log('formdata: ' + formData.salutation);
        formData.append("_method", "PUT");
        const eurl = `/api/registration/${this.$route.params.regId}/houseowner-detail/${this.newhouseownerinfo.id}`;
        this.$axios
          .post(eurl, formData)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.isLoading = false;
              this.newhouseownerinfo = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successfully Updated";
              if (isNext) {
                this.nextStep(1);
              }
              this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    edit(id) {
      //    const url = `api/registration/${this.$route.params.regId}/houseownerinfo/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/houseowner-detail/${id}`;
      this.$axios.get(url).then((response) => {
        this.newhouseownerinfo = response.data.data;
        this.uploadedDocument = this.basePath + response.data.data.photo;
        // console.log(response.data.data.photo);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/houseowner-detail/${id}`;
      this.$axios
        .delete(deleteurl)
        .then((response) => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            // this.alertDelete("Successfully Deleted !!");
            this.snackbar.show = true;
            this.successfulMessage = "Successfully Deleted";
            const i = this.houseownerinfo.map((item) => item.id).indexOf(id); // find index of your object
            this.houseownerinfo.splice(i, 1);
          }
          // console.log(response);
        })
        .catch((error) => {
          this.alertError(error);
        });
    },
    async getNewRegData() {
      let regDataTemp = this.$store.getters.registrationData;
      try {
        if (!Object.keys(regDataTemp).length || regDataTemp.id != this.$route.params.id) {
          await this.$store.dispatch("getRegistration", this.$route.params.regId)
        }
        this.newRegData = this.$store.getters.registrationData;
        this.newhouseownerinfo.name = this.newRegData.houseowner_name;
        this.newhouseownerinfo.name_np = this.newRegData.houseowner_name_np;
        this.newhouseownerinfo.ward_no = this.newRegData.ward_no;
        this.newhouseownerinfo.phone_no = this.newRegData.contact_no;
        this.newhouseownerinfo.address = this.newRegData.location;
      }
      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}

.v-text-field__details {
  margin: 0;
  padding: 0;
}

label span {
  color: red;
}

.error-message {
  padding: 10px !important;
}

.table-title {
  font-size: 17px;
}
</style>
